{
  "composer.placeholder": "Ответить {name}",
  "composer.placeholderInit": "Скажи что-нибудь {name}",
  "composer.send": "Отправить",
  "header.conversations": "Разговоры",
  "header.deleteConversation": "Вы уверены, что хотите удалить всю беседу?",
  "header.deleteConversationYes": "удалять",
  "header.deleteConversationNo": "отменить",
  "conversationList.untitledConversation": "Разговор без названия",
  "conversationList.title": "Разговор {id}",
  "botInfo.backToConversation": "Назад к разговору",
  "botInfo.startConversation": "Начать разговор",
  "botInfo.privacyPolicy": "Показать Политику конфиденцеальности",
  "botInfo.termsAndConditions": "Показать Условия использования",
  "botInfo.preferredLanguage": "Предпочитаемый язык",
  "store.resetSessionMessage": "Начать разговор заново",
  "loginForm.userName": "Логин",
  "loginForm.password": "Пароль",
  "loginForm.submit": "Отправить",
  "loginForm.formTitle": "Форма для входа",
  "loginForm.providedCredentials": "Введенные данные",
  "messages.newMessage": "Новое сообщение",
  "messages.newMessages": "новые сообщения"
}
