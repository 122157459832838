{
  "composer.dropdownPlaceholder": "Select a choice",
  "composer.placeholder": "Reply to {name}",
  "composer.placeholderInit": "Say something to {name}",
  "composer.message": "Message to send",
  "composer.send": "Send",
  "composer.sendMessage": "Send Message",
  "composer.interact": "Interact with your chatbot",
  "footer.poweredBy": "We're {icon} by {link}",
  "header.conversations": "Conversations",
  "header.hideChatWindow": "Hide the chat window",
  "header.deleteConversation": "Are you sure you want to delete this entire conversation?",
  "header.deleteConversationYes": "Delete",
  "header.deleteConversationNo": "Cancel",
  "conversationList.untitledConversation": "Untitled Conversation",
  "conversationList.title": "Conversation {id}",
  "botInfo.backToConversation": "Back to Conversation",
  "botInfo.startConversation": "Start Conversation",
  "botInfo.privacyPolicy": "View Privacy Policy",
  "botInfo.termsAndConditions": "View Terms of Service",
  "botInfo.preferredLanguage": "Preferred Language",
  "store.resetSessionMessage": "Reset the conversation",
  "loginForm.userName": "Username",
  "loginForm.password": "Password",
  "loginForm.submit": "Submit",
  "loginForm.formTitle": "Login form",
  "loginForm.providedCredentials": "Provided credentials",
  "message.botSaid": "Virtual assistant said : ",
  "message.fromBotLabel": "Virtual Assistant",
  "message.fromMeLabel": "Me",
  "message.iSaid": "I said : ",
  "messages.newMessage": "New message",
  "messages.newMessages": "New messages",
  "messages.readMore": "Read More",
  "messages.showLess": "Show Less",
  "message.thumbsUp": "Thumbs Up",
  "message.thumbsDown": "Thumbs Down",
  "widget.title": "Chat window",
  "widget.toggle": "Toggle chatbot window"
}
