{
  "composer.dropdownPlaceholder": "Sélectionnez un choix",
  "composer.placeholder": "Répondre à {name}",
  "composer.placeholderInit": "Dites quelque chose à {name}",
  "composer.message": "Message à envoyer",
  "composer.send": "Envoyer",
  "composer.sendMessage": "Envoyer le message",
  "composer.interact": "Interagir avec votre assistant virtuel",
  "footer.poweredBy": "Propulsé par {link}",
  "header.conversations": "Conversations",
  "header.hideChatWindow": "Cacher la fenêtre de clavardage",
  "header.deleteConversation": "Êtes-vous certain de vouloir supprimer cette conversation?",
  "header.deleteConversationYes": "Supprimer",
  "header.deleteConversationNo": "Annuler",
  "conversationList.untitledConversation": "Conversation sans titre",
  "conversationList.title": "Conversation {id}",
  "botInfo.backToConversation": "Retour à la conversation",
  "botInfo.startConversation": "Démarrer la conversation",
  "botInfo.privacyPolicy": "Politique de confidentialité",
  "botInfo.termsAndConditions": "Termes et conditions",
  "botInfo.preferredLanguage": "Langue préférée",
  "store.resetSessionMessage": "Réinitialisation de la conversation",
  "loginForm.userName": "Identifiant",
  "loginForm.password": "Mot de passe",
  "loginForm.submit": "Envoyer",
  "loginForm.formTitle": "Formulaire de connexion",
  "loginForm.providedCredentials": "Identifiants renseignés",
  "message.botSaid": "L'assistant virtuel a dit : ",
  "message.fromBotLabel": "Assistant virtuel",
  "message.fromMeLabel": "Moi",
  "message.iSaid": "J'ai dit : ",
  "messages.newMessage": "Nouveau message",
  "messages.newMessages": "Nouveaux messages",
  "messages.readMore": "Afficher la suite",
  "messages.showLess": "Afficher moins",
  "message.thumbsUp": "Très bien",
  "message.thumbsDown": "Mauvais",
  "widget.title": "Fenêtre de clavardage",
  "widget.toggle": "Ouvrir ou fermer la conversation"
}
