{
  "composer.placeholder": "Відповісти {name}",
  "composer.placeholderInit": "Скажи щось {name}",
  "composer.send": "Надіслати",
  "header.conversations": "Розмови",
  "header.deleteConversation": "Ви впевнені, що хочете видалити всю цю розмову?",
  "header.deleteConversationYes": "Видалити",
  "header.deleteConversationNo": "Скасувати",
  "conversationList.untitledConversation": "Розмова без назви",
  "conversationList.title": "Розмова {id}",
  "botInfo.backToConversation": "Повернутись до розмови",
  "botInfo.startConversation": "Розпочати розмову",
  "botInfo.privacyPolicy": "Показати Політику конфіденційності",
  "botInfo.termsAndConditions": "Показати Умови користування",
  "botInfo.preferredLanguage": "Бажана мова",
  "store.resetSessionMessage": "Розпочати розмову заново",
  "loginForm.userName": "Логін",
  "loginForm.password": "Пароль",
  "loginForm.submit": "Надіслати",
  "loginForm.formTitle": "Форма для входу",
  "loginForm.providedCredentials": "Введені дані",
  "messages.newMessage": "Нове повідомлення",
  "messages.newMessages": "нові повідомлення"
}
