{
  "composer.placeholder": "Responder a {name}",
  "composer.placeholderInit": "Decir algo a {name}",
  "composer.send": "Enviar",
  "header.conversations": "Conversaciones",
  "header.deleteConversation": "¿Estás seguro de que deseas eliminar toda esta conversación?",
  "header.deleteConversationYes": "Eliminar",
  "header.deleteConversationNo": "Cancelar",
  "conversationList.untitledConversation": "Conversación sin título",
  "conversationList.title": "Conversacion {id}",
  "botInfo.backToConversation": "Volver a conversar",
  "botInfo.startConversation": "Iniciar conversación",
  "botInfo.privacyPolicy": "Ver política de privacidad",
  "botInfo.termsAndConditions": "Ver términos de servicio",
  "botInfo.preferredLanguage": "Preferred Language",
  "store.resetSessionMessage": "Restablecer la conversación",
  "messages.newMessage": "Nuevo mensaje",
  "messages.newMessages": "Nuevos mensajes"
}
