{
  "composer.placeholder": "Fale com {name}",
  "composer.placeholderInit": "Diga alguma coisa para {name}",
  "composer.send": "Mandar",
  "header.conversations": "Conversas",
  "header.deleteConversation": "Tem certeza de que deseja excluir toda esta conversa?",
  "header.deleteConversationYes": "Excluir",
  "header.deleteConversationNo": "Cancelar",
  "conversationList.untitledConversation": "Conversa sem título",
  "conversationList.title": "Conversa {id}",
  "botInfo.backToConversation": "De volta à conversa",
  "botInfo.startConversation": "Iniciar conversa",
  "botInfo.privacyPolicy": "Ver política de privacidade",
  "botInfo.termsAndConditions": "Ver termos de serviço",
  "botInfo.preferredLanguage": "Preferred Language",
  "store.resetSessionMessage": "Redefinir a conversa",
  "messages.newMessage": "Nova mensagem",
  "messages.newMessages": "Novas mensagens"
}
