{
  "composer.placeholder": "Schreibe {name}",
  "composer.placeholderInit": "Sag etwas zu {name}",
  "composer.message": "Nachricht zum Versenden",
  "composer.send": "Senden",
  "header.conversations": "Unterhaltungen",
  "header.hideChatWindow": "Das Chatfenster verstecken",
  "header.deleteConversation": "Möchten Sie das gesamte Gespräch wirklich löschen?",
  "header.deleteConversationYes": "Löschen",
  "header.deleteConversationNo": "Stornieren",
  "conversationList.untitledConversation": "Unbenannte Unterhaltung",
  "conversationList.title": "Konversation {id}",
  "botInfo.backToConversation": "Zurück zur Unterhaltung",
  "botInfo.startConversation": "Unterhaltung beginnen",
  "botInfo.privacyPolicy": "Datenschutzerklärung anzeigen",
  "botInfo.termsAndConditions": "Geschäftsbedingungen anzeigen",
  "botInfo.preferredLanguage": "Bevorzugte Sprache",
  "store.resetSessionMessage": "Unterhaltung zurücksetzen",
  "loginForm.userName": "Benutzername",
  "loginForm.password": "Passwort",
  "loginForm.submit": "Absenden",
  "loginForm.formTitle": "Anmeldeformular",
  "loginForm.providedCredentials": "Bereitgestellte Anmeldedaten",
  "message.botSaid": "Virtuelle Assistent sagte : ",
  "message.fromBotLabel": "Virtueller Assistent",
  "message.fromMeLabel": "Ich",
  "message.iSaid": "Ich sagte : ",
  "messages.newMessage": "Neue Nachricht",
  "messages.newMessages": "Neue Nachrichten",
  "widget.title": "Chatfenster",
  "widget.toggle": "Chatfenster ein- oder ausblenden"
}
