{
  "composer.dropdownPlaceholder": "Seleziona una scelta",
  "composer.placeholder": "Rispondi a {name}",
  "composer.placeholderInit": "Scrivi qualcosa a {name}",
  "composer.message": "Messaggio da inviare",
  "composer.send": "Invia",
  "footer.poweredBy": "We're {icon} by {link}",
  "header.conversations": "Conversazioni",
  "header.hideChatWindow": "Nascondi la finestra chat",
  "header.deleteConversation": "Sei sicuro di voler eliminare l'intera conversazione?",
  "header.deleteConversationYes": "Elimina",
  "header.deleteConversationNo": "Annulla",
  "conversationList.untitledConversation": "Conversazione senza titolo",
  "conversationList.title": "Conversazione {id}",
  "botInfo.backToConversation": "Ritorna alla Conversazione",
  "botInfo.startConversation": "Avvia Conversazione",
  "botInfo.privacyPolicy": "Vedi Privacy Policy",
  "botInfo.termsAndConditions": "Vedi Terms of Service",
  "botInfo.preferredLanguage": "Lingua preferita",
  "store.resetSessionMessage": "Azzera conversazione",
  "loginForm.userName": "Username",
  "loginForm.password": "Password",
  "loginForm.submit": "Invia",
  "loginForm.formTitle": "Login form",
  "loginForm.providedCredentials": "Credenziali fornite",
  "message.botSaid": "Virtual assistant dice : ",
  "message.fromBotLabel": "Virtual Assistant",
  "message.fromMeLabel": "Io",
  "message.iSaid": "Io ho detto : ",
  "messages.newMessage": "Nuovo messaggio",
  "messages.newMessages": "Nuovi messaggi",
  "messages.readMore": "Leggi altro",
  "messages.showLess": "Mostra meno",
  "message.thumbsUp": "Pollice Su",
  "message.thumbsDown": "Pollice Giù",
  "widget.title": "Finestra Chat",
  "widget.toggle": "Togli finestra chat"
}
